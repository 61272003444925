import React, { useEffect } from "react";
import InstructionPageSpeaker from "../assets/logo/InstructionPageSpeaker.svg";
import LeftSideGradient from "../assets/Gradients/TopLeft.svg";
import RightSideGradient from "../assets/Gradients/BottomLeft.svg";
import Button from "../components/utils/Button";
import Certified from "../assets/logo/InstructionIcons/Certified.svg";
import Questions from "../assets/logo/InstructionIcons/Questions.svg";
import Submit from "../assets/logo/InstructionIcons/Submit.svg";
import Time from "../assets/logo/InstructionIcons/Time.svg";
import CheckIcon from "../assets/logo/InstructionIcons/check.svg";
import { useDispatch, useSelector } from "react-redux";
import { startExam } from "../store/slices/exam";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const InstructionPage = () => {
  const { exam } = useSelector((state) => state.exam);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const startNewExam = async (exam_id) => {
    try {
      await dispatch(startExam(exam_id));
    } catch (error) {
      setError("Unable to start exam");
    }
  };
  const redirectToExma = async () => {
    if (exam && exam?.started_at === null) {
      setLoading(true);
      await startNewExam(exam.id);
      setLoading(false);
    }
    navigate("/take-exam");
  };
  useEffect(() => {
    if (!exam) {
      navigate("/");
    }
  }, [exam]);
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <>
      <div className="relative z-20 grid w-full h-full grid-cols-1 gap-6 pt-10 overflow-hidden lg:grid-cols-5 layout font-primary">
        <div className="flex flex-col col-span-3 h-max">
          <p className="text-[#4D8075] font-light text-lg">Exam Instructions</p>
          <p className="text-5xl font-bold leading-normal text-black">
            {exam?.exam?.name}
          </p>
          <p className="text-lg font-light">{exam?.exam?.descrition}</p>
        </div>
        <div className="flex-col items-end hidden col-span-2 gap-20 lg:flex h-max">
          <img
            src={InstructionPageSpeaker}
            alt="Instruction Page Speaker"
            // className="bg-red-500"
          />
        </div>
      </div>

      <div className="grid w-full gap-8 text-black layout xl:grid-cols-5">
        <div className="flex flex-col gap-3 lg:-mt-20 xl:col-span-2">
          <p className="text-xl font-bold">What is E-LMIS Psychometric Test?</p>
          <p className="text-xs text-neutral-400 lg:text-sm">
            This test consist of various assessments that are designed to
            understand your ability, personality and interests so as to guide
            your personal and career development journey.
          </p>

          <div className="flex items-center gap-2">
            <img src={CheckIcon} alt="CheckIcon" />
            <p className="text-xs lg:text-sm text-neutral-400">
              To Discover personal and career development pathway
            </p>
          </div>
          <div className="flex items-center gap-2">
            <img src={CheckIcon} alt="CheckIcon" />
            <p className="text-xs lg:text-sm text-neutral-400">
              To Connect with professional career guidance service
            </p>
          </div>
          <div className="flex items-center gap-2">
            <img src={CheckIcon} alt="CheckIcon" />
            <p className="text-xs lg:text-sm text-neutral-400">
              To get insights about your talents, strength and weakness
            </p>
          </div>
        </div>

        <div className="flex flex-col justify-center gap-2 p-4 bg-white rounded-lg">
          <img src={Time} alt="Time" className="h-14 w-14" />
          <p className="font-semibold">Exam Time</p>

          <p className="text-xs text-neutral-400">
            You have {formatTime(exam?.exam?.duration)} minutes to complete the
            exam
          </p>
        </div>

        <div className="flex flex-col justify-center gap-2 p-4 bg-white rounded-lg">
          <img src={Questions} alt="Questions" className="h-14 w-14" />
          <p className="font-semibold">Total Exam Questions</p>

          <p className="text-xs text-neutral-400">
            {exam?.exam?.total_questions} questions in total
          </p>
        </div>

        <div className="flex flex-col justify-center gap-2 p-4 bg-white rounded-lg">
          <img src={Submit} alt="Submit" className="h-14 w-14" />
          <p className="font-semibold">Make Sure to Submit</p>

          <p className="text-xs text-neutral-400">
            Make sure to submit your answers before the time runs out
          </p>
        </div>
      </div>

      <div className="flex items-end justify-end w-full mt-10 mb-5 layout">
        <Button
          handleClick={redirectToExma}
          disabled={loading}
          custom={`bg-primary text-white border-primary ${
            loading ? "opacity-85" : ""
          }`}
        >
          <div className="flex items-center justify-center gap-x-2">
            Start the Exam
            <svg width="14" height="13" viewBox="0 0 14 13" fill="none">
              <path
                d="M0 6.4H12M12 6.4L6.6 1M12 6.4L6.6 11.8"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          </div>
        </Button>
      </div>

      <img
        src={LeftSideGradient}
        alt="Left Side Gradient"
        className="fixed top-0 left-0 -z-10"
      />
      <img
        src={RightSideGradient}
        alt="Right Side Gradient"
        className="fixed bottom-0 -right-20 rotate-[270deg] -z-10"
      />
    </>
  );
};

export default InstructionPage;
