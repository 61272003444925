import React from "react";
import BiquLogo from "../../assets/logo/BiquLogo.svg";
import LogoText from "../../assets/logo/LogoText.svg";
import BottomLeft from "../../assets/Gradients/BottomLeft.svg";
import Button from "../utils/Button";
const Navbar = () => {
  return (
    <>
      <div className="flex justify-between w-full py-5 layout">
        <div className="flex items-center gap-5 max-w-52">
          <img src={BiquLogo} alt="Biqu Logo" className="w-14" />
          <img src={LogoText} alt="Logo Text" className="" />
        </div>
        <Button
          custom={
            "text-primary hidden border-primary hover:bg-primary hover:text-white"
          }
        >
          Contact Us
        </Button>
      </div>
    </>
  );
};

export default Navbar;
