import React, { useEffect } from "react";
import useFullscreenStatus from "./useFullscreenStatus";
import Navbar from "../components/layout/Navbar";
import { MdOutlineStart } from "react-icons/md";
import Button from "../components/utils/Button";
import { useNavigate } from "react-router-dom";

export default function MaximizableView({ children, backgroundColor, exam }) {
    const maximizableElement = React.useRef(null);
    const FullScreenButton = React.useRef(null);
    const navigate = useNavigate();
    const [stattExamModal, setStartExamModal] = React.useState(false);
    let isFullscreen, setIsFullscreen;
    let errorMessage;

    try {
        [isFullscreen, setIsFullscreen] = useFullscreenStatus(maximizableElement);
    } catch (e) {
        errorMessage = "Fullscreen not supported";
        isFullscreen = true;
        setIsFullscreen = undefined;
    }
    React.useEffect(() => {
        setIsFullscreen();
    }, []);
    useEffect(() => {
        console.log(isFullscreen);
        if (!isFullscreen) {
            setStartExamModal(true);
        }
        else {
            setStartExamModal(false);
        }
    }, [isFullscreen]);
    const continueExam = () => {
        setIsFullscreen();

    }

    return (
        <div
            ref={maximizableElement}
            className={`maximizable-container overflow-y-auto ${isFullscreen ? "fullscreen" : "default"
                }`}
            style={{ backgroundColor: isFullscreen ? backgroundColor : null }}
        >
            {
                isFullscreen && (
                    <Navbar />
                )
            }
            <div className="maximizable-content">{children}</div>
            <div className=" maximizable-actions"

            >

                {stattExamModal
                    && (
                        <div className="fixed top-0 left-0 right-0 z-50 flex flex-col items-center justify-center w-screen h-screen bg-black/5 backdrop-blur-sm">
                            <div className="relative w-full max-h-full overflow-auto bg-white rounded-lg shadow lg:w-1/4">
                                <div className="w-full h-full ">
                                    <div className="flex flex-col items-center p-8 rounded-t gap-y-5 md:p-5">
                                        <MdOutlineStart className="w-10 h-10 text-primary" />
                                        <h3 className="text-lg lg:text-xl text-center font-semibold text-[#555555]">
                                            {exam?.exam?.name} Exam
                                        </h3>
                                        <p className="text-sm text-center lg:text-base">
                                            {exam?.exam?.descrition.length > 50
                                                ? exam?.exam?.descrition.slice(0, 50) + "..."
                                                : exam?.exam?.descrition}
                                        </p>
                                    </div>
                                    <div className="flex items-center  px-8 py-4 bg-[#F4F3F8] justify-center gap-4 mt-4">
                                        <Button
                                            handleClick={continueExam}
                                            custom="bg-primary text-white border-primary"
                                        >
                                            Continue Exam
                                        </Button>
                                        <Button
                                            handleClick={() => {
                                                navigate("/");
                                            }}
                                            custom="bg-white !font-light"
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        </div>
    );
}
