import React from "react";
import TopGradient from "../../../assets/Gradients/TopLeft.svg";
import BottomGradient from "../../../assets/Gradients/BottomRight.svg";
import Button from "../../utils/Button";

const ContinueExamModal = (props) => {
  const continueExam = () => {
    props.continueExam();
  };

  return (
    <>
      <div className="fixed top-0 left-0 h-screen w-full backdrop-blur-sm z-[999] flex justify-center items-center  bg-black/20">
        <div className="relative flex items-center justify-center w-4/5 overflow-hidden bg-white h-4/5 rounded-2xl">
          <img
            src={TopGradient}
            className="absolute z-20 rotate-90 -top-36 -left-32"
            alt="gradient"
          />
          <img
            src={BottomGradient}
            className="absolute bottom-0 z-20 -right-32"
            alt="gradient"
          />

          <div className="flex flex-col gap-6 lg:gap-10 items-center justify-center w-full text-black z-[99]">
            <p className="text-2xl font-semibold text-center capitalize md:text-3xl lg:text-5xl lg:text-start font-primary">
              Continue Exam
            </p>
            <p className="max-w-2xl text-sm font-semibold text-center capitalize md:text-base lg:text-xl font-primary">
              If you wish to continue the exam, you can only access the
              questions you haven’t answered in the previous examinations.
            </p>

            <div className="flex flex-col items-center justify-center gap-4 mt-5 lg:mt-10">
              <p className="max-w-md text-lg font-semibold text-center capitalize lg:text-2xl font-primary">
                Questions
              </p>

              <div className="flex gap-4">
                <p className="text-[#009C93] text-6xl lg:text-8xl font-bold flex items-center">
                  {props?.answeredQuestions} /{" "}
                  <span className="mt-4 text-4xl text-black lg:text-6xl">
                    {props?.totalQuestions}
                  </span>
                </p>
              </div>
            </div>

            <div className="flex items-center gap-6 mt-10">
              <Button
                handleClick={continueExam}
                custom={"bg-[#009C93] text-white w-44 border-[#009C93]"}
              >
                Continue
              </Button>
              {/* <Button
                handleClick={closeBrowserTab}
                custom={"bg-black text-white w-44 border-black"}
              >
                Cancel
              </Button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContinueExamModal;
