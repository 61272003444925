import React from "react";

const Button = ({ handleClick, children, custom, disabled }) => {
  return (
    <>
      <button
        disabled={disabled}
        onClick={handleClick}
        className={`${custom} px-8 py-2 font-medium h-max text-sm border-2 flex gap-4 items-center justify-center rounded-md transition-all duration-200 ease-in-out active:scale-95`}
      >
        {children}
      </button>
    </>
  );
};

export default Button;
