import React, { useRef, useState, useEffect } from "react";
import BottomRight from "../assets/Gradients/BottomRight.svg";
import TopLeft from "../assets/Gradients/TopLeft.svg";
import Button from "../components/utils/Button";
import FaceScanIcon from "../assets/FaceScan/FaceScanIcon.svg";
import { MdVerifiedUser } from "react-icons/md";
import Webcam from "react-webcam";
import { useNavigate } from "react-router-dom";
import { faceIdentify } from "../store/slices/exam";
import { useDispatch } from "react-redux";

const FaceScan = () => {
  const [photo, setPhoto] = useState(null);
  const dispatch = useDispatch();
  const base64ToBlob = (base64Data) => {
    try {
      const parts = base64Data.split(";base64,");
      const contentType = parts[0].split(":")[1];
      const raw = window.atob(parts[1]);
      setPhoto(raw);
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uInt8Array], { type: contentType });
    } catch (error) {}
  };
  const [faceResult, setFaceResult] = useState(0);
  const webcamRef = useRef(null);
  const [imageUrl, setImageUrl] = useState("");
  const capture = () => {
    try {
      setImageUrl(
        URL.createObjectURL(base64ToBlob(webcamRef.current.getScreenshot()))
      );
    } catch (error) {}
  };

  const navigate = useNavigate();

  const scanFace = async () => {
    try {
      await capture();

      // dispatch(faceIdentify(photo));
      setFaceResult(1);
    } catch (error) {
      setFaceResult(2);
    }
  };
  return (
    <>
      <div className="flex flex-col gap-4 justify-center items-center h-[88vh] w-full pb-12">
        <img src={FaceScanIcon} alt="Face Scan Icon" />
        <p className="text-4xl font-bold text-black capitalize sm:text-5xl">
          Face Recognition
        </p>
        <p className="max-w-sm text-sm font-light text-center text-black ">
          In order to facilitate your exam experience and ensure timely
          completion, it is essential to familiarize yourself with the following
          rules and instructions prior to the examination.
        </p>

        {/* <div className="w-64 my-8 overflow-hidden border border-black rounded-lg">
<Webcam mirrored={true} height={1300} />
</div> */}
        <div className="flex gap-x-10">
          <div>
            <div className="relative flex justify-center my-10 h-fit w-72">
              <div className="w-4/5 corner-border h-3/4">
                <div className=""></div>
              </div>

              <Webcam
                mirrored={true}
                ref={webcamRef}
                className="w-full h-full"
              />
            </div>
          </div>
          {faceResult !== 0 && (
            <div className="relative flex justify-center place-items-center">
              {faceResult === 1 ? (
                <div className="absolute bottom-10 flex place-items-center gap-x-2 rounded-lg bg-[#71E17C1A] px-10 py-3 font-medium text-[#53C330]">
                  <MdVerifiedUser size={20} />
                  Verified
                </div>
              ) : faceResult === 2 ? (
                <div className="absolute bottom-10 flex place-items-center gap-x-2 rounded-lg bg-[#FFB7B7] px-10 py-3 font-medium text-[#FF5C5C]">
                  <MdVerifiedUser size={20} />
                  Not Verified
                </div>
              ) : null}

              <img className="h-56 w-72" src={imageUrl} alt="" />
            </div>
          )}
        </div>
        <Button
          handleClick={scanFace}
          custom={"bg-primary text-white border-primary w-64"}
        >
          Scan Face
        </Button>
      </div>

      <img
        src={BottomRight}
        alt="Bottom Right Gradient"
        className="fixed bottom-0 right-0 -z-10 w-[30vw]"
      />
      <img
        src={TopLeft}
        alt="Top Left Gradient"
        className="fixed top-0 left-0 -z-10 w-[30vw]"
      />
    </>
  );
};

export default FaceScan;
