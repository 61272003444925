import React, { useEffect, useRef } from "react";
import BottomLeft from "../../assets/Gradients/BottomLeft.svg";
import TopRight from "../../assets/Gradients/TopRight.svg";
import BottomRight from "../../assets/Gradients/BottomRight.svg";
import TopLeft from "../../assets/Gradients/TopLeft.svg";
import ExamGuy from "../../assets/AnnimationComponents/BottomLeft/Group 1160444287.png";
import GreenCard from "../../assets/AnnimationComponents/BottomLeft/GreenCard.svg";
import RedCard from "../../assets/AnnimationComponents/BottomLeft/RedCard.svg";
import TimeIsUp from "../../assets/AnnimationComponents/BottomLeft/TimeIsUp.svg";
import CertifiedCard from "../../assets/AnnimationComponents/BottomRight/CertifiedCard.svg";
import CertifiedPerson from "../../assets/AnnimationComponents/BottomRight/CertifiedPerson.svg";
import GetCertified from "../../assets/AnnimationComponents/BottomRight/GetCertified.svg";
import EasyToUse from "../../assets/AnnimationComponents/TopLeft/EasyToUse.svg";
import GetHired from "../../assets/AnnimationComponents/TopLeft/GetHired.svg";
import ThumbsUpPerson from "../../assets/AnnimationComponents/TopLeft/Group 1160444286.png";
import FaceScan from "../../assets/AnnimationComponents/TopRight/Group 1160444264.png";
import SuccessFaceScan from "../../assets/AnnimationComponents/TopRight/SuccessFaceScan.svg";
import gsap from "gsap";
import { useLocation } from "react-router-dom";

const HeroAnimation = () => {
  const BottomLeftRef = useRef(null);
  const TopRightRef = useRef(null);
  const BottomRightRef = useRef(null);
  const TopLeftRef = useRef(null);
  const location = useLocation();

  // Bottom Left Cards Ref
  const ExamGuyRef = useRef(null);
  const GreenCardRef = useRef(null);
  const RedCardRef = useRef(null);
  const TimeIsUpRef = useRef(null);

  // Top Right Cards Ref
  const FaceScanRef = useRef(null);
  const SuccessFaceScanRef = useRef(null);

  // Bottom Right Cards Ref
  const CertifiedCardRef = useRef(null);
  const CertifiedPersonRef = useRef(null);
  const GetCertifiedRef = useRef(null);

  // Top Left Cards Ref
  const EasyToUseRef = useRef(null);
  const GetHiredRef = useRef(null);
  const ThumbsUpPersonRef = useRef(null);

  useEffect(() => {
    try {
      const tl = gsap.timeline({ repeat: -1 });

      const animateFirst = () => {
        const tl2 = gsap.timeline();
        const tl = gsap.timeline();
        const tl3 = gsap.timeline();
        const tl4 = gsap.timeline();
        const tl5 = gsap.timeline();
        const tl6 = gsap.timeline();
        const tl7 = gsap.timeline();

        tl2.fromTo(
          [BottomLeftRef?.current, TopRightRef?.current],
          { opacity: 0 },
          { opacity: 1, duration: 2 }
        );
        tl2.to([BottomLeftRef?.current, TopRightRef?.current], {
          duration: 6,
          opacity: 1,
        });
        tl2.to([BottomLeftRef?.current, TopRightRef?.current], {
          opacity: 0,
          duration: 2,
        });

        tl.fromTo(
          [TimeIsUpRef?.current],
          { bottom: "-100%", opacity: 0 },
          { bottom: "1rem", duration: 2, opacity: 1 }
        );
        tl.to([TimeIsUpRef?.current], {
          duration: 6,
          bottom: "1rem",
          opacity: 1,
        });
        tl.to([TimeIsUpRef?.current], {
          bottom: "-100%",
          duration: 2,
          opacity: 0,
        });

        tl3.fromTo(
          [GreenCardRef?.current],
          { left: "-100%", opacity: 0 },
          { left: "11vw", duration: 2, opacity: 1 }
        );
        tl3.to([GreenCardRef?.current], {
          duration: 6,
          left: "11vw",
          opacity: 1,
        });
        tl3.to([GreenCardRef?.current], {
          left: "-100%",
          duration: 2,
          opacity: 0,
        });

        tl4.fromTo(
          [RedCardRef?.current],
          { left: "-100%", opacity: 0 },
          { left: "1rem", duration: 2, opacity: 1 }
        );
        tl4.to([RedCardRef?.current], {
          duration: 6,
          left: "1rem",
          opacity: 1,
        });
        tl4.to([RedCardRef?.current], {
          left: "-100%",
          duration: 2,
          opacity: 0,
        });

        tl5.fromTo(
          [ExamGuyRef?.current],
          { left: "-100%", opacity: 0 },
          { left: "7vw", duration: 2, delay: 0.2, opacity: 1 }
        );
        tl5.to([ExamGuyRef?.current], {
          duration: 6,
          left: "7vw",
          opacity: 1,
        });
        tl5.to([ExamGuyRef?.current], {
          left: "-100%",
          duration: 2,
          opacity: 0,
          delay: 0,
        });

        tl6.fromTo(
          [FaceScanRef?.current],
          { right: "-100%", opacity: 0 },
          { right: "8vw", duration: 2, delay: 0.2, opacity: 1 }
        );
        tl6.to([FaceScanRef?.current], {
          duration: 6,
          right: "8vw",
          opacity: 1,
        });
        tl6.to([FaceScanRef?.current], {
          right: "-100%",
          duration: 2,
          opacity: 0,
          delay: 0,
        });

        tl7.fromTo(
          [SuccessFaceScanRef?.current],
          { right: "-100%", opacity: 0 },
          { right: "3vw", duration: 2, opacity: 1 }
        );
        tl7.to([SuccessFaceScanRef?.current], {
          duration: 6,
          right: "3vw",
          opacity: 1,
        });
        tl7.to([SuccessFaceScanRef?.current], {
          right: "-100%",
          duration: 2,
          opacity: 0,
          delay: 0,
        });

        tl.eventCallback("onComplete", animateSecond);
      };

      const animateSecond = () => {
        try {
          const tl2 = gsap.timeline();
          const tl = gsap.timeline();
          const tl3 = gsap.timeline();
          const tl4 = gsap.timeline();

          tl2.fromTo(
            [BottomRightRef?.current, TopLeftRef?.current],
            { opacity: 0 },
            { opacity: 1, duration: 2 }
          );
          tl2.to([BottomRightRef?.current, TopLeftRef?.current], {
            duration: 6,
            opacity: 1,
          });
          tl2.to([BottomRightRef?.current, TopLeftRef?.current], {
            opacity: 0,
            duration: 2,
            onComplete: animateFirst,
          });

          tl.fromTo(
            [CertifiedCardRef?.current],
            { right: "-100%", opacity: 0 },
            { right: 0, duration: 2, opacity: 1 }
          );
          tl.to([CertifiedCardRef?.current], {
            duration: 6,
            right: 0,
            opacity: 1,
          });
          tl.to([CertifiedCardRef?.current], {
            right: "-100%",
            duration: 2,
            opacity: 0,
          });

          tl3.fromTo(
            [CertifiedPersonRef?.current],
            { right: "-100%", opacity: 0 },
            { right: "9vw", duration: 2.2, opacity: 1 }
          );
          tl3.to([CertifiedPersonRef?.current], {
            duration: 6,
            right: "9vw",
            opacity: 1,
          });
          tl3.to([CertifiedPersonRef?.current], {
            right: "-100%",
            duration: 2,
            opacity: 0,
          });

          tl4.fromTo(
            [GetCertifiedRef?.current],
            { right: "-100%", opacity: 0 },
            { right: "6vw", duration: 2.4, opacity: 1 }
          );
          tl4.to([GetCertifiedRef?.current], {
            duration: 6,
            right: "6vw",
            opacity: 1,
          });
          tl4.to([GetCertifiedRef?.current], {
            right: "-100%",
            duration: 2,
            opacity: 0,
          });
        } catch (error) {
          tl.kill();
        }
      };

      animateFirst();

      return () => {
        tl.kill();
      };
    } catch (error) {}
  }, []);

  return (
    <>
      <div>
        <img
          ref={BottomLeftRef}
          src={BottomLeft}
          alt="Bottom Left Gradient"
          className="fixed bottom-0 left-0 opacity-0 -z-10"
        />
        <img
          ref={ExamGuyRef}
          src={ExamGuy}
          alt="Exam Guy"
          className="fixed bottom-44 left-[8vw] hidden lg:block max-h-36 max-w-40 z-0 opacity-0"
        />
        <img
          ref={GreenCardRef}
          src={GreenCard}
          alt="Green Card"
          className="fixed bottom-20 left-[10vw] hidden lg:block max-h-36 max-w-48 -z-10 opacity-0"
        />
        <img
          ref={RedCardRef}
          src={RedCard}
          alt="Red Card"
          className="fixed hidden opacity-0 bottom-10 left-4 lg:block max-h-36 max-w-48 -z-10"
        />
        <img
          ref={TimeIsUpRef}
          src={TimeIsUp}
          alt="Time Is Up"
          className="fixed bottom-4 left-[8vw] hidden lg:block max-h-36 max-w-32 xl:max-w-40 opacity-0"
        />
      </div>

      <div>
        <img
          ref={TopRightRef}
          src={TopRight}
          alt="Top Right Gradient"
          className="fixed top-0 right-0 opacity-0 -z-10"
        />
        <img
          ref={FaceScanRef}
          src={FaceScan}
          alt="Face Scan"
          className="fixed right-0 z-20 hidden opacity-0 bottom-20 lg:block max-h-72"
        />
        <img
          ref={SuccessFaceScanRef}
          src={SuccessFaceScan}
          alt="Success Face Scan"
          className="fixed right-0 z-10 hidden opacity-0 bottom-36 lg:block max-h-72 max-w-56"
        />
      </div>
      <div>
        <img
          ref={BottomRightRef}
          src={BottomRight}
          alt="Bottom Right Gradient"
          className="fixed bottom-0 right-0 opacity-0 -z-10"
        />

        <img
          ref={CertifiedCardRef}
          src={CertifiedCard}
          alt="Certified Card"
          className="fixed z-10 hidden opacity-0 bottom-32 right-10 lg:block max-h-64"
        />
        <img
          ref={CertifiedPersonRef}
          src={CertifiedPerson}
          alt="Certified Person"
          className="fixed z-20 hidden opacity-0 bottom-28 right-44 lg:block max-h-60 max-w-24"
        />
        <img
          ref={GetCertifiedRef}
          src={GetCertified}
          alt="Get Certified"
          className="fixed z-10 hidden opacity-0 bottom-16 right-28 lg:block max-h-24 max-w-40"
        />
      </div>
      <div>
        <img
          ref={TopLeftRef}
          src={TopLeft}
          alt="Top Left Gradient"
          className="fixed top-0 left-0 opacity-0 -z-10"
        />
        <img
          ref={EasyToUseRef}
          src={EasyToUse}
          alt="Easy To Use"
          className="fixed top-0 left-0 opacity-0 -z-10"
        />
        <img
          ref={GetHiredRef}
          src={GetHired}
          alt="Get Hired"
          className="fixed top-0 left-0 opacity-0 -z-10"
        />
        <img
          ref={ThumbsUpPersonRef}
          src={ThumbsUpPerson}
          alt="Thumbs Up Person"
          className="fixed top-0 left-0 opacity-0 -z-10"
        />
      </div>
    </>
  );
};

export default HeroAnimation;
