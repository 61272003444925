import { gql } from '@apollo/client';

export const GET_EXAMS = gql`
   query GetExamsForMe{
  psychometric_test {
    exam_taker {
      id
      started_at
      available_time
      labor_id
      registration_id
      exam {
        id
        name
        duration
        total_questions
        duration_type
        published
        category {
          id
          name
          description
          sub_categories {
            name
            id
            description
          }
        }
      }
    }
  }
}

    `;

export const REFRESH_TOKEN = gql`
mutation refreshToken {
  refreshToken {
    tokens {
      access_token
      refresh_token
    }
    data {
      email
      id
      phoneNumber
      role
    }
  }
}
`;


export const GET_EXAM = gql`
query EXAM($exam_id: psychometric_test_uuid!) {
  psychometric_test {
    exams_by_pk(id: $exam_id) {
      id
      name
      descrition
      duration
      duration_type
      total_questions
      category {
        id
        name
        description
        __typename
      }
      __typename
    }
    __typename
  }
}`;


export const GET_NEXT_QUESTION = gql`
query GetNextQuestionByExamTakerId($exam_taker_id: psychometric_test_uuid!) {
  psychometric_test {
    exam_taker_by_pk(id: $exam_taker_id) {
      id
      started_at
      available_time
      answers(order_by: {created_at: desc}, 
      where: {submitted_at: {_is_null: true}, choice_id: {_is_null: true}}, 
      limit: 1) {
        id
        exam_question {
          id
          order
          exam{
            id
            duration
            total_questions
          }
          question {
            choices {
              id
              choice_text
            }
            duration
            question
          }
        }
      }
    }
  }
}
`;



export const GET_QUESTION_BACK = gql`query GetQuestionBack($exam_taker_id: psychometric_test_uuid! $order: Int!) {
  psychometric_test {
    exam_taker_by_pk(
      id:$exam_taker_id
    ) {
      id
      answers(
        where: {exam_question: {order: {_eq: $order}}}, 
        limit: 1
      ) {
        id
        choice_id
        exam_question {
          id
          order
          question {
            choices {
              id
              choice_text
            }
            duration
            question
          }
        }
      }
    }
  }
}
`;



export const GET_EXAM_TAKER = gql`
query GetExamTaker($exam_taker_id: psychometric_test_uuid!) {
  psychometric_test {
    exam_taker_by_pk(id: $exam_taker_id) {
      id
      started_at
      available_time
      labor_id
      registration_id
      answers_aggregate
      {
        aggregate{
          count
        }
      }
      exam {
        id
        name
        duration
        total_questions
        duration_type
        published
        descrition
        category {
          id
          name
          description
          sub_categories {
            name
            id
            description
          }
        }
      }
    }
  }
}
`;


export const START_EXAM = gql`
mutation START_EXAM($exam_taker_id: psychometric_test_uuid!) {
  psychometric_test{
    startAnExam(exam_taker_id:$exam_taker_id)
    {
      message
      error
    }
  }
}`


export const SUBMIT_ANSWER = gql`
mutation SUBMIT_ANSWER($answer_id: psychometric_test_uuid! $choice_id: psychometric_test_uuid!, $time_left: Int!) {
  psychometric_test{
    submitAnswer(answer_id:$answer_id choice_id:$choice_id time_left: $time_left)
    {
      error
      message
    }
  }
}`

export const FINISH_EXAM = gql`
mutation FinishExam($exam_taker_id: psychometric_test_uuid!) {
  psychometric_test {
    finishExam(exam_taker_id: $exam_taker_id) {
      error
      message
    }
  }
}
`

export const GET_RESULT = gql`
query GetResult($examTakerId: psychometric_test_uuid!){
  psychometric_test{
    exam_taker_by_pk(id:$examTakerId){
      exam{
        name
        descrition
        category{
          name
          description
        }
      }
      results{
        sub_category{
          name
          id
        }
        result_text{
          text
        }
      }
    }
  }
}
`


export const FACE_IDENTIFY = gql`
mutation faceIdentifyMutation($photo:String!){
  faceIdentify(photo:$photo){
    details
  }
}
`




export const UPDATE_AVAILABLE_TIME = gql`
mutation UPDATE_AVAILABLE_TIME($available_time: Int! $exam_id: psychometric_test_uuid!) {
  psychometric_test {
    updateAvailableTime(available_time: $available_time, exam_id: $exam_id) {
      error
      success
    }
  }
}
`

