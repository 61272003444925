import React from "react";

const MultipleChoiceBar = ({
  handleClick,
  isSelected,
  optionName,
  optionText,
  disabled,
}) => {
  function submit() {
    if (disabled) return;
    else handleClick();
  }
  return (
    <>
      <div className="flex items-center w-full gap-4 choice-card">
        <p className="text-xl font-medium text-[#BFBFBF]">{optionName}.</p>
        {/* ${
            disabled ? "bg-gray-300" : ""
          }  */}
        <div
          onClick={submit}
          className={`${
            isSelected
              ? "bg-[#4D8075] text-white"
              : disabled
              ? "bg-gray-100"
              : "active:scale-95 cursor-pointer"
          }  py-5 px-10 gap-12 flex-1 select-none flex justify-between items-center border border-[#D4D4D4] rounded-md   transition-all ease-in-out duration-200 `}
        >
          <p
            dangerouslySetInnerHTML={{
              __html: optionText,
            }}
          ></p>
          <div
            className={`${
              isSelected ? "border-white border-2" : "border-[#BFBFBF]"
            } h-6 w-6 rounded-full border `}
          ></div>
        </div>
      </div>
    </>
  );
};

export default MultipleChoiceBar;
