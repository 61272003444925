import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import FaceScan from "./pages/FaceScan.jsx";
import Home from "./pages/Home.jsx";
import TakeExam from "./pages/TakeExam.jsx";
import InstructionPage from "./pages/InstructionPage.jsx";

function App() {
  return (
    <>
      <Navbar />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/face-scan" element={<FaceScan />} />
          <Route path="/take-exam" element={<TakeExam />} />
          <Route path="/instruction" element={<InstructionPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
